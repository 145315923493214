<template>
  <div v-if="info.status" class="user-withdrawal-detail">
    <div class="detail">
      <div class="profile">
        <div class="bank">
          <div v-if="info.bankIcon" class="pic">
            <img :src="info.bankIcon" class="img">
          </div>
          <div v-else class="pic">
            <img :src="icon1" class="img">
          </div>
          <div class="title">{{ info.bankCardName }}</div>
        </div>
        <div class="price">{{ info.amount | changeUnit }}</div>
      </div>
      <div class="progress" :class="activeIcon">
        <div class="notice">处理进度</div>
        <van-steps
          class="step-box"
          direction="vertical"
          :active-icon="activeIcon"
          active-color
          inactive-icon="checked"
          :active="status"
        >
          <van-step :class="{ doline:info.status==1 }">
            <div class="item">
              <div class="head">
                <div class="title">发起提现</div>
                <div class="date">{{ info.createdTime | parseTime }}</div>
              </div>
            </div>
          </van-step>
          <van-step :class="{clear: activeIcon=='clear',doing: info.status==1}">
            <div class="item">
              <div class="head">
                <div class="title">提现中</div>
              </div>
            </div>
          </van-step>
          <van-step v-if="status != 3" :class="{faild: activeIcon == 'clear'}">
            <div class="item">
              <div class="head">
                <div class="title">{{ activeTitle }}</div>
                <div v-if="info.reviewTime" class="date">{{ info.reviewTime | parseTime }}</div>
              </div>
              <div v-if="activeIcon=='checked'" class="des">
                <div class="tt">温馨提示:</div>
                <div class="ct">具体到账情况请以银行实际到账时间为准。</div>
              </div>
              <div v-else class="des">
                <div class="tt">失败原因:</div>
                <div class="ct">{{ info.memo }}</div>
              </div>
            </div>
          </van-step>
        </van-steps>
      </div>
      <div class="content">
        <div class="item">
          <div class="title">提现到</div>
          <div class="cont">{{ info.bankCardName }} ({{ info.bankCardNo }})</div>
        </div>
        <div class="item">
          <div class="title">流水号</div>
          <div class="cont">{{ info.orderNo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from '@/assets/withdrawal/bank.png'
import { parseTime } from '@/utils/index'
import { getWithdrawalDetail } from '@/api/user'
export default {
  filters: {
    // 将单位转化为元
    changeUnit(val) {
      if (!val) return 0.0
      return (val / 100).toFixed(2)
    },
    parseTime(val) {
      return parseTime(val, '{m}-{d} {h}:{i}')
    }
  },
  data() {
    return {
      activeIcon: '', // clear失败  checked成功
      activeTitle: '',
      id: '',
      info: {},
      status: 2,
      icon1
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getWithdrawalDetail()
  },
  methods: {
    async getWithdrawalDetail() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getWithdrawalDetail({ id: this.id })
      const data = res.datas
      this.info = data
      if (data.status === 3 || data.status === 10 || data.status === 11) {
        this.status = 2
        if (data.status === 3) {
          this.activeIcon = 'checked'
          this.activeTitle = '提现成功'
        } else {
          this.activeIcon = 'clear'
          this.activeTitle = '提现失败'
        }
      } else {
        this.status = 3
      }
      loading.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.user-withdrawal-detail{
  position: relative;
  .profile{
    padding-top: 0.933333rem;
    .bank{
      display: flex;
      justify-content: center;
      align-items: center;
      .img{
        display: block;
        width: 0.986667rem;
        height: 0.986667rem;
      }
      .title{
        padding-left: 0.4rem;
        font-size: 0.4rem;
      }
    }
    .price{
      padding: 0.4rem 0 1.6rem 0;
      font-size: 0.64rem;
      text-align: center;
    }
  }
  .detail::v-deep{
    position: relative;
    z-index: 2;
    .content {
      background: #fff;
    }
    .progress{
      display: flex;
      padding: .4rem .266667rem;
      border-radius: .2rem;
      background:#fff;
      .notice{
        width: 3rem;
        font-size: 0.373333rem;
        color: #888888;
      }
      .step-box{
        flex: 1;
      }
      &.close{
        .van-step--process{
          .item{
            .title{
              color: #F43530;
            }
          }
          .van-icon{
            color: #F43530;
          }
        }
      }
      &.checked{
        .van-step--process{
          .item{
            .title{
              color: #fb7a00;
            }
          }
          .van-icon{
            color: #fb7a00;
          }
        }
      }
      .item{
        padding-top: .12rem;
        padding-bottom: .666667rem;
        .head{
          display: flex;
          .title{
            flex:1;
            font-size: 0.373333rem;
            color: #fd7a00;
          }
          .date{
            font-size: .373333rem;
            color: #979799;
          }
        }
        .des{
          padding-top: .266667rem;
          font-size: 0.32rem;
          color: #979799;
          .ct{
            padding-top: .066667rem;
          }
        }
      }
      .van-steps__items{
        .van-step{
          &.close{
            .van-step__line{
              background-color: #eb5d56;
            }
          }
          &.doing{
            .van-icon-passed:before{
              color: #cdcdcd;
              content: '\F066';
            }
          }
          &.doline{
            .van-step__line:after{
              position: absolute;
              left: 0;
              bottom:2px;
              content:' ';
              width: .06rem;
              height:50%;
              background:#cdcdcd;
            }
          }
        }
      }
      .van-step__title{
        line-height: 1;
      }
      .van-step__circle{
        width: .32rem;
        height: .32rem;
      }
      .van-step--finish .van-step__line{
        background-color:#ff7902;
      }
      .van-step--vertical .van-step__line{
        width: .06rem;
        left: -0.21rem;
      }
      .van-step:last-child .van-step__line{
        width:0;
      }
      .van-step--vertical{
        padding:0 0 0 .4rem;
        &.doing {
          .van-step__title {
            .title {
              color: #999999;
            }
          }
          .van-icon-checked:before {
            content: '\e6c4';
            color: #cccccc;
          }
        }
        &.faild {
          .van-step__title {
            .title {
              color: #f86767;
            }
          }
          .van-icon-clear:before {
            color: #f86767;
          }
        }
      }
      .van-steps--vertical{
        padding: 0 0 0 .6rem;
      }
      .van-step--vertical .van-step__circle-container{
        display: flex;
        top: .36rem;
        left: -0.18rem;
        font-size: .6rem;
        color: #fd7a00;
        background: #fff;
      }
      .van-step--vertical:not(:last-child)::after{
        border:0;
      }
    }
    .content{
      padding: .2rem .266667rem;
      .item{
        display: flex;
        align-items: center;
        padding: .266667rem 0;
        .title{
          flex: 1;
          font-size: .373333rem;
          color: #979799;
        }
        .cont{
          font-size: .373333rem;
          color: #323233;
        }
      }
    }
  }
}
</style>
